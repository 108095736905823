.str-chat__date-separator-line {
    display: none;
}

.str-chat__date-separator-date {
    text-transform: uppercase;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    color: #7e7e7e;
}

.str-chat__date-separator-date:before,
.str-chat__date-separator-date:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: auto;
}

.str-chat__date-separator-date:before {
    margin-right: 15px;
}

.str-chat__date-separator-date:after {
    margin-left: 15px;
}

.chat-message-text p {
    margin: 0;
    /* TODO: figure out how to get super-long text to wrap */
    /* flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word; */
}

.str-chat__ul {
    list-style: none;
    padding: 0 18px;
}

.str-chat__list {
    overflow-y: auto;
}

.str-chat__list-notifications {
    color: red;
    height: fit-content;
    padding: 0 30px;
    font-size: 14px;
}

.rfu-file-input {
    display: none;
}

.str-chat__textarea,
.rfu-file-upload-button,
.rfu-file-upload-button > label {
    display: flex;
    justify-content: center;
    align-items: center;
}

.str-chat__textarea {
    height: fit-content;
}

.rfu-file-upload-button {
    height: 100%;
}

.hidden {
    display: none;
}

.str-chat__typing-indicator {
    display: none;
}

.str-chat__load-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px;
    padding-top: 10px;
}

.str-chat__load-more-button__button {
    height: 32px;
    width: 128px;
    background-color: #2081C3;
    border: none;
    color: #FFF;
    font-family: 'Avenir Next', 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 2px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);;
}

.str-chat__load-more-button__button:hover {
    cursor: pointer;
    background-color: #0B6CAE;
}

.str-chat__empty-channel {
    display: none;
}

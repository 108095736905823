@import url('https://fonts.googleapis.com/css?family=Comfortaa|Lato&display=swap');

body {
  color: #7e7e7e;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Comfortaa', sans-serif;
  color: #2081c3;
  font-weight: 700;
  font-size: 1.875rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h1 span.heading-number {
  color: '#000000'
}
h2, h3, h4, h5, h6 {
  color: #4a4a4a;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.125rem
}
h4, h5, h6 {
  font-size: 1rem;
}
a {
  color: #2081c3;
}

.StripeElement {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-top: 16px;
    height: 40px;
    width: 100%;
  
    padding: 10px 12px;
  
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
  
    -webkit-box-shadow: 0 1px 3px 0 #e6ebf1;
  
            box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    -webkit-transition: -webkit-box-shadow 150ms ease;
    transition: -webkit-box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease, -webkit-box-shadow 150ms ease;
    color: #7b7a7a;

    font-family: 'Avenir Next', 'Lato', sans-serif;
  }
  
  .StripeElement--focus {
    -webkit-box-shadow: 0 1px 3px 0 #cfd7df;
            box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }  
  .sticky {
      position: sticky;
      top: 0
  }

  #hubspot-messages-iframe-container {
    z-index: 1100 !important;
  }

  /* removes the reveal password icon on forms in IE && Edge. We implement our own */
  ::-ms-reveal {
    display: none;
  }

  @-webkit-keyframes pulse_animation {
0% { -webkit-transform: scale(1); transform: scale(1); }
50% { -webkit-transform: scale(1.08); transform: scale(1.08); }
100% { -webkit-transform: scale(1); transform: scale(1); }
}

  @keyframes pulse_animation {
0% { -webkit-transform: scale(1); transform: scale(1); }
50% { -webkit-transform: scale(1.08); transform: scale(1.08); }
100% { -webkit-transform: scale(1); transform: scale(1); }
}

@-webkit-keyframes update_pulse_animation {
0% { -webkit-box-shadow: 0 0 0 0 rgba(32, 129, 195, 0.7); box-shadow: 0 0 0 0 rgba(32, 129, 195, 0.7); }
70% { -webkit-box-shadow: 0 0 0 4px rgba(32, 129, 195, 0); box-shadow: 0 0 0 4px rgba(32, 129, 195, 0); }
100% { -webkit-box-shadow: 0 0 0 0 rgba(32, 129, 195, 0); box-shadow: 0 0 0 0 rgba(32, 129, 195, 0); }
}

@keyframes update_pulse_animation {
0% { -webkit-box-shadow: 0 0 0 0 rgba(32, 129, 195, 0.7); box-shadow: 0 0 0 0 rgba(32, 129, 195, 0.7); }
70% { -webkit-box-shadow: 0 0 0 4px rgba(32, 129, 195, 0); box-shadow: 0 0 0 4px rgba(32, 129, 195, 0); }
100% { -webkit-box-shadow: 0 0 0 0 rgba(32, 129, 195, 0); box-shadow: 0 0 0 0 rgba(32, 129, 195, 0); }
}

/* Smartbanner */
.smartbanner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1125;
  background: #fff;
  overflow-x: hidden;
  width: 100%;
  height: 84px;
}

.smartbanner__icon {
  left: 13px;
  border-radius: 0;
}

.smartbanner__button {
	width: 65x;
	height: 30px;
	border-radius: 44px;
	border: 1px;
	background: #2081C3;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: .1rem;
	display: -ms-flexbox;
	display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  top: 43px;
  padding-right: 8px;
  right: 13px;
  font-family: Avenir Next, Open Sans;
}

.smartbanner__info {
  left: 90px;
}

.smartbanner__info__title {
	font-family: Avenir Next, Open Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
  margin-bottom: 5px;
  color: #4A4A4A;
}

.smartbanner__info__author, .smartbanner__info__price {
  font-family: Avenir Next, Open Sans;
  color: #7E7E7E;
}

.smartbanner__exit {
  position: absolute;
  left: auto;
  top: 10px;
  right: 13px;
}

.smartbanner__exit::before, .smartbanner__exit::after {
  font-family: Avenir Next, Open Sans;
  background: #4A4A4A;
  height: 14px;
}
.str-chat__message-attachment {
    height: auto;
    line-height: 0;
    margin: 2px 0 16px;
    width: 100%;
    outline: none;
}
.str-chat__message-attachment span {
    position: relative !important;
    width: 100% !important;
}

.str-chat__message-attachment span > img {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

.str-chat__message-attachment:last-child {
    margin: 2px 0;
}

.str-chat__ul {
    list-style: none;
    padding: 0 18px;
}

.str-chat__list {
    overflow-y: auto;
}

.str-chat__list-notifications {
    color: red;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0 30px;
    font-size: 14px;
}

.rfu-file-input {
    display: none;
}

.str-chat__textarea,
.rfu-file-upload-button,
.rfu-file-upload-button > label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.str-chat__textarea {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.rfu-file-upload-button {
    height: 100%;
}

.hidden {
    display: none;
}

.str-chat__typing-indicator {
    display: none;
}

.str-chat__load-more-button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 84px;
    padding-top: 10px;
}

.str-chat__load-more-button__button {
    height: 32px;
    width: 128px;
    background-color: #2081C3;
    border: none;
    color: #FFF;
    font-family: 'Avenir Next', 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 2px;
    -webkit-box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);;
}

.str-chat__load-more-button__button:hover {
    cursor: pointer;
    background-color: #0B6CAE;
}

.str-chat__empty-channel {
    display: none;
}

.str-chat__date-separator-line {
    display: none;
}

.str-chat__date-separator-date {
    text-transform: uppercase;
    font-size: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    color: #7e7e7e;
}

.str-chat__date-separator-date:before,
.str-chat__date-separator-date:after {
    content: '';
    -ms-flex: 1 1;
        flex: 1 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: auto;
}

.str-chat__date-separator-date:before {
    margin-right: 15px;
}

.str-chat__date-separator-date:after {
    margin-left: 15px;
}

.chat-message-text p {
    margin: 0;
    /* TODO: figure out how to get super-long text to wrap */
    /* flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word; */
}


.str-chat__message-attachment {
    height: auto;
    line-height: 0;
    margin: 2px 0 16px;
    width: 100%;
    outline: none;
}
.str-chat__message-attachment span {
    position: relative !important;
    width: 100% !important;
}

.str-chat__message-attachment span > img {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

.str-chat__message-attachment:last-child {
    margin: 2px 0;
}
